import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { BootstrapVue} from 'bootstrap-vue'
import '../node_modules/animate.css'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.config.productionTip = false
Vue.use (VueAnimateOnScroll)
Vue.use(BootstrapVue)
new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
