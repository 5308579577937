<template>
  <v-app id="app">
    <NavBar class="NavBar"></NavBar>
    <NavMobile />
    <router-view />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import NavMobile from "@/components/NavMobile";
import Home from "@/views/Home";
export default {
  name: "App",
  components: {
    NavBar,
    Home,
    NavMobile,
  },
};
</script>

<style scoped>
.NavMobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .NavBar {
    display: none;
  }
}
</style>
