<template>
  <v-card class="mx-auto overflow-hidden" elevation="0">
    <v-app-bar style="background-color:#340668;color:white">
      <!--<v-app-bar-nav-icon
        @click="drawer = true"
        style="color:#98d02f"
      ></v-app-bar-nav-icon>-->
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon style="color:#98d02f">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
            <v-list-item to="/">
              <v-list-item-title style="color:#340668">HOME</v-list-item-title>
            </v-list-item>
            <v-list-item to="Atuacao">
              <v-list-item-title style="color:#340668">SERVIÇOS</v-list-item-title>
            </v-list-item>
            <v-list-item to="Sobre">
              <v-list-item-title style="color:#340668">QUEM SOMOS</v-list-item-title>
            </v-list-item>
            <v-list-item to="Publicacoes">
              <v-list-item-title style="color:#340668">PUBLICAÇÕES</v-list-item-title>
            </v-list-item>
            <v-list-item to="clientes&parceiros">
              <v-list-item-title style="color:#340668">CLIENTES E PARCEIROS</v-list-item-title>
            </v-list-item>
            <!--<v-list-item to="/">
            <v-list-item-icon>
              <v-icon style="color:#340668">mdi-blogger</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="color:#340668">BLOG</v-list-item-title>
          </v-list-item>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon style="color:#340668">mdi-school-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="color:#340668">CURSOS ONLINE & MENTORIA</v-list-item-title>
            </v-list-item>-->
            <v-list-item to="Contact">
              <v-list-item-title style="color:#340668">CONTATOS</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-toolbar-title>
        <v-container class="text-center">
          <img style="width:25%" src="@/assets/logo-mobile.png" alt />
        </v-container>
      </v-toolbar-title>
    </v-app-bar>
  </v-card>
</template>
<script>
export default {
  name: "NavMobile",
  data: () => ({
    drawer: false,
    group: null
  })
};
</script>
