import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: function () {
      return import('../views/Home.vue')
    }
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: function () {
      return import('../views/Sobre.vue')
    }
  },
  {
    path: '/atuacao',
    name: 'Atuacao',
    component: function () {
      return import('../views/Atuacao.vue')
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: function () {
      return import('../views/Contact.vue')
    }
  },
  {
    path: '/clientes&parceiros',
    name: 'ClientesParceiros',
    component: function () {
      return import('../views/ClientesParceiros.vue')
    }
  },
  {
    path: '/Publicacoes',
    name: 'Publicacoes',
    component: function () {
      return import('../views/Publicacoes.vue')
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
})

export default router