<template>
  <div class="p-1 lider">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="5000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/SERVICOS.jpg"
            alt="image slot"
          />
        </template>
        <v-card
          class="title"
          elevation="5"
          style="    
              position: absolute;
              right: -10em;
              top: -30em;
              padding: 2em;
              background-color: transparent;
              min-width: 25%;
              max-height: 10em;"
        >
          <h2>
            <router-link
              to="Atuacao"
              style="text-decoration: none;color: white;font-weight:bolder"
              >SERVIÇOS</router-link
            >
          </h2>
          <p style="font-size:arge;color:white">Como podemos te ajudar?</p>
        </v-card>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/NOSSA HISTORIA.jpg"
            alt="image slot"
          />
        </template>
        <v-card
          class="title"
          elevation="5"
          style="    
              position: absolute;
              right: -10em;
              top: -30em;
              padding: 2em;
              background-color: transparent;
              min-width: 25%;
              height: 6em;"
        >
          <h2>
            <router-link
              to="Sobre#hist"
              style="text-decoration: none;color: #98D02F;font-weight:bolder;margin-top:2em"
              >NOSSA HISTÓRIA</router-link
            >
          </h2>
        </v-card>
      </b-carousel-slide>
      <!-- <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/BLOG.jpg"
            alt="image slot"
          >
        </template>
        <v-card 
          class="title"
          elevation="5"
          style="    
              position: absolute;
              right: -10em;
              top: -30em;
              padding: 2em;
              background-color: transparent;
              min-width: 25%;
              height: 10em;">
        <h2><a 
          href=""
          style="text-decoration: none;color: white;font-weight:bolder"
          >BLOG</a></h2>
          <p style="font-size:arge;color:white">Mantenha-se atualizado sobre os principais assuntos do mundo científico.</p>
        </v-card>
      </b-carousel-slide> -->
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/COMPROMISSO.jpg"
            alt="image slot"
          />
        </template>
        <v-card
          class="title"
          elevation="5"
          style="    
              position: absolute;
              right: -10em;
              top: -30em;
              padding: 2em;
              background-color: transparent;
              min-width: 25%;
              height: 10em;"
        >
          <h2>
            <router-link
              to="Sobre#sustent"
              style="text-decoration: none;color: #98D02F;font-weight:bolder"
              >COMPROMISSO</router-link
            >
          </h2>
          <p style="font-size:arge;color:#98D02F">
            Práticas sustentáveis e <br />
            métodos alternativos ao uso de animais.
          </p>
        </v-card>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/MENTORIA.jpg"
            alt="image slot"
          />
        </template>
        <v-card
          class="title"
          elevation="5"
          style="    
              position: absolute;
              right: -10em;
              top: -30em;
              padding: 2em;
              background-color: transparent;
              min-width: 25%;
              height: 10em;"
        >
          <h2>
            <router-link
              to="Atuacao#ment"
              style="text-decoration: none;color: #330667;font-weight:bolder;"
              >MENTORIA</router-link
            >
          </h2>
          <p style="font-size:arge;color:#330667">
            Nossa contribuição para o seu desenvolvimento
          </p>
        </v-card>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Carrousel",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .title {
    position: absolute;
    right: -2.5em !important;
    top: -150px !important;
    text-align: center !important;
    padding: 0em !important;
    background-color: transparent;
    width: 70% !important;
    height: 2em !important;
    z-index: -1;
  }
  .title h2 a {
    text-decoration: none;
    color: #330667;
    font-weight: bolder;
    font-size: large !important;
    line-height: 0.5px;
  }
  .title p {
    font-size: 10px !important;
  }
  .lider {
    margin-top: -3em;
  }
}
</style>
