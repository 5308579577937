<template>
  <div class="servicos" style="text-align:center; padding:10em;">
    <b-container>
      <div v-animate-onscroll="{ down: 'animated pulse' }">
        <h1 class="text-center" style="color:#330667;font-weight: bold;">
          SERVIÇOS
        </h1>
        <br />
      </div>
    </b-container>
    <div class="cada" v-animate-onscroll="{ down: 'animated pulse' }">
      <b-container>
        <b-row>
          <b-col>
            <router-link to="Atuacao#degrada">
              <img
                src="@/assets/Ativos.png"
                alt=""
                style="        width: 80%;
                                           height: 90%;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#element">
              <img
                src="@/assets/Quali.png"
                alt=""
                style="     width: 55%;
                                            height: 60%;
                                            margin-top:.5em;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#lixi">
              <img
                src="@/assets/veneno.png"
                alt=""
                style="            width: 75%;
                                               height: 85%;
                                               margin-left: 2em;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#nitro">
              <img
                src="@/assets/Estab.png"
                alt=""
                style="            width: 70%;
                                               height: 93%;
                                               margin-top: -.5em;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#pred">
              <img
                src="@/assets/Atomo.png"
                alt=""
                style="    width: 60%;
                                       height: 60%;
                                       margin-top: .5em;"
              />
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4>PRODUTOS DE DEGRADAÇÃO</h4>
          </b-col>
          <b-col>
            <h4>IMPUREZAS ELEMENTARES</h4>
          </b-col>
          <b-col>
            <h4>EXTRAÍVEIS E LIXIVIÁVEIS</h4>
          </b-col>
          <b-col>
            <h4>IMPUREZAS MUTAGÊNICAS - Nitrosaminas</h4>
          </b-col>
          <b-col>
            <h4>PREDIÇÃO <i>in silico</i></h4>
          </b-col>
        </b-row>
        <br />
        <br />
        <b-row>
          <b-col>
            <router-link to="Atuacao#sint">
              <img
                src="@/assets/Impu.png"
                alt=""
                style="        width: 80%;
                                           height: 90%;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#PDE">
              <img
                src="@/assets/Muta.png"
                alt=""
                style="        width: 80%;
                                           height: 90%;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#vet">
              <img
                src="@/assets/degrada.png"
                alt=""
                style="         width: 80%;
                                                height: 85%;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#ASAP">
              <img
                src="@/assets/ASAP.png"
                alt=""
                style="        width: 80%;
                                           height: 90%;"
              />
            </router-link>
          </b-col>
          <b-col>
            <router-link to="Atuacao#ment">
              <img
                src="@/assets/remedio.png"
                alt=""
                style="        width: 80%;
                                           height: 90%;"
              />
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4>Consultoria em Quimica Medicinal e Síntese Orgânica</h4>
          </b-col>
          <b-col>
            <h4>HBEL/PDE (Limite de Exposição com Base em Saúde)</h4>
          </b-col>
          <b-col>
            <h4>Indústria Veterinária - LMR</h4>
          </b-col>
          <b-col>
            <h4>ASAPprime®</h4>
            <h4>FusionQbD®</h4>
          </b-col>
          <b-col>
            <h4>Cursos e Mentorias</h4>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="cadaMobile">
      <b-row>
        <b-col>
          <img
            src="@/assets/degrada.png"
            alt=""
            style="            width: 80%;
                                           height: 85%;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>IMPUREZAS E PRODUTOS DE DEGRADAÇÃO</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/Quali.png"
            alt=""
            style="        width: 55%;
                                           height: 60%;
                                           margin-top:.5em;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>IMPUREZAS ELEMENTARES</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/veneno.png"
            alt=""
            style="            width: 75%;
                                               height: 85%;
                                               margin-left: 2em;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>EXTRAÍVEIS E LIXIVIÁVEIS</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/Estab.png"
            alt=""
            style="            width: 70%;
                                           height: 93%;
                                           margin-top: -.5em;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>IMPUREZAS MUTAGÊNICAS</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/Atomo.png"
            alt=""
            style="    width: 60%;
                                   height: 60%;
                                   margin-top: .5em;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>PREDIÇÃO <i>in silico</i></h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/Impu.png"
            alt=""
            style="        width: 80%;
                                       height: 90%;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>ATIVOS x EXCIPIENTES</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/Muta.png"
            alt=""
            style="        width: 80%;
                                           height: 90%;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>LIMITE DE EXPOSIÇÃO - PDE</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/Ativos.png"
            alt=""
            style="        width: 80%;
                                               height: 90%;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>PREDIÇÃO DA ESTABILIDADE</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/ASAP.png"
            alt=""
            style="        width: 80%;
                                               height: 90%;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>ASAPprime®</h5>
          <br />
          <h5>FusionQbD®</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            src="@/assets/remedio.png"
            alt=""
            style="        width: 80%;
                                               height: 90%;"
          />
        </b-col>
        <b-col class="d-flex text-center mt-4 ">
          <h5>TREINAMENTOS</h5>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "Servicos",
};
</script>

<style scoped>
.servs {
  text-decoration: none;
  color: #330667;
}
.servs:active .servs:hover {
  color: #330667;
}
.servs h4,
h4 {
  font-weight: bolder;
}
.cadaMobile {
  display: none;
}
h4 {
  color: #330667;
}
hr {
  width: 40%;
  margin-top: 2em;
  margin-bottom: 3em;
  height: 10px;
  background-color: rgb(152, 208, 47) !important;
}
@media only screen and (max-width: 768px) {
  .servicos {
    padding: 0 !important;
  }
  .cada {
    display: none;
  }
  .cadaMobile {
    display: block;
    color: #330667;
    text-align: center !important;
  }
  .col {
    border: none !important;
    font-style: italic !important;
  }
}
row {
  display: table-cell;
}
hr {
  width: 100% !important;
  margin-top: 2em;
  margin-bottom: 0em;
  height: 4px;
}
</style>
