<template>
  <div class="home">
    <br />
    <carrousel class="carrousel" id="carrousel" />
    <br />
    <br />
    <servicos style="margin-bottom: -20em" />
    <br />
    <br />
    <missao />
    <br />
    <br />
    <Diferencial></Diferencial>
    <br />
    <br />
    <Clientes></Clientes>
    <br />
    <br />
    <b-container class="mt-5 text-center social">
      <v-row>
        <v-col>
          <h5 style="color:#330667">Redes Sociais</h5>
          <br />
          <b-link
            target="_blank"
            href="https://www.instagram.com/spektra_solucoes_cientificas/"
          >
            <i
              class="fab fa-instagram fa-2x ml-1"
              style="color:#330667!important;font-size:4em"
            ></i>
          </b-link>
          <b-link
            target="_blank"
            href="https://www.linkedin.com/company/spektra-consultoria/"
          >
            <i
              class="fab fa-linkedin fa-2x ml-4"
              style="color:#330667!important;font-size:4em"
            ></i>
          </b-link>
        </v-col>
      </v-row>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Diferencial from "../components/Diferencial";
import Clientes from "../components/Clientes";
import Footer from "../components/Footer";
import Carrousel from "../components/Carrousel.vue";
import Missao from "../components/Missao.vue";
import Servicos from "../components/Servicos.vue";
export default {
  name: "Home",
  components: {
    Footer,
    Diferencial,
    Clientes,
    Carrousel,
    Missao,
    Servicos,
  },
  mounted() {
    this.customroute = this.$router.currentRoute.fullPath;
    if (this.customroute === "/") {
      this.$vuetify.goTo("#carrousel");
    }
  },
};
</script>
<style scoped>
.carrousel {
  margin-top: -4em;
}
.social {
  display: none;
}
@media only screen and (max-width: 600px) {
  .carrousel {
    margin-top: 0em !important;
  }
  .social {
    display: block;
  }
}
</style>
