<template>
    <b-container>
        <div v-animate-onscroll="{down: 'animated pulse'}">
            <h1 class="text-center mt-5" style="color:#330667;font-weight: bold;">COMPROVADO</h1>
        </div>
        
        <b-row style="margin: 5em 0 0 0">
            <b-col>
                <img src="@/assets/ANVISA-logo.png" alt="" style="border-right:1px solid #330667; width:60%;">
            </b-col>
            <b-col style="margin-top:2em;">
                <div v-animate-onscroll="{down: 'animated pulse'}">
                    <h3 style="font-weight: bold;color:#330667">100% DE APROVAÇÃO</h3> <br>
                </div>
                <div style="color:#330667;font-size: large;">
                    Temos a aprovação em todos os projetos e relatórios enviados para a <b>ANVISA</b>.
                    <br>
                    Buscamos sempre uma proximidade com a agência para promover discussões com o objetivo de evoluirmos juntos no conhecimento, principalmente dos temas mais recentes e em discussão no âmbito internacional.  
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name:'Diferencial'
}
</script>
<style scoped>
@media only screen and (max-width: 768px){

hr{
    width:100% !important;
    margin-top: 2em;
    margin-bottom:0em !important;
    height: 4px!important;
    background-color: rgb(152, 208, 47) !important;
}
}
</style>