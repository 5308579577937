<template>
    <b-container>
        <div class="text-center py-3 mb-5" 
             style="opacity:0.3;">
            <img class="text-center mb-4" 
            src="@/assets/logo-spektra.png"
            style="width:8%;">
            <p>© 2020 SPEKTRA Design by W.P.I</p>
        </div>
    </b-container>
</template>

<script>
export default {
    name:'Footer'
}
</script>