<template>
  <b-container>
    <div
      class="parceiros text-center container p-5 mb-5"
      v-animate-onscroll="{ down: 'animated pulse' }"
    >
      <div class="sub">
        <h1 style="color:#330667;font-weight: bold;">PARCEIROS</h1>
      </div>
      <b-row style="margin-top:6em;" class="grid">
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="http://www.apexscience.com.br/"
          >
            <img
              style="width:70%"
              src="@/assets/parceiros/apexscience.png"
              alt="Apex Science"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://lychnoflora.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/parceiros/lychnoflora.png"
              alt="Lychnoflora"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.vitaraconsulting.com/homepage-pt"
          >
            <img
              style="width:70%;"
              src="@/assets/parceiros/vita.png"
              alt="Vita Consulting"
            />
          </b-link>
        </b-col>
      </b-row>
      <b-row style="margin-top:6em;" class="grid">
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="http://www.smatrix.com/"
          >
            <img
              style="width:70%"
              src="@/assets/parceiros/s_matrix.png"
              alt="S-Matrix Fusion Parceiro"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.lhasalimited.org/"
          >
            <img
              style="width:70%;"
              src="@/assets/parceiros/lhasa.gif"
              alt="Lhasa Limited Parceiro"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://freethinktech.com/"
          >
            <img
              style="width:70%;"
              src="@/assets/parceiros/freethink.png"
              alt="Free Think Parceiro"
            />
          </b-link>
        </b-col>
      </b-row>
      <b-row class="baixa" style="margin-top:10em">
        <b-col>
          <b-link
            style="background-color:transparent;width:17.5%"
            target="_blank"
            href="https://www.qspharma.com.br/"
          >
            <img
              style="width:50%"
              src="@/assets/parceiros/qsPharma.svg"
              alt="QS Pharma Parceiro"
            />
          </b-link>
        </b-col>
      </b-row>
    </div>
    <div
      class="parceiros text-center container p-5 mb-5"
      v-animate-onscroll="{ down: 'animated pulse' }"
    >
      <div class="sub">
        <h1 style="color:#330667;font-weight: bold;">CLIENTES</h1>
      </div>
      <b-row style="margin-top:6em;" class="grid">
        <b-col class="itens">
          <b-link
            style="background-color:transparent;"
            target="_blank"
            href="https://www.sanofi.com.br/pt/quem-somos/areas-de-atuacao/medley"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/sanofi.png"
              alt="Sanofi-Medley"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.cristalia.com.br/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/cristalia.png"
              alt="Cristália"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://eurofarma.com.br/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/eurofarma.jpg"
              alt="Eurofarma"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.ache.com.br/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/ache.svg"
              alt="Ache"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.uniaoquimica.com.br/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/uniao.png"
              alt="Inovat (União Quimica)"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.biolabfarma.com.br/pt/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/biolab.jpg"
              alt="Biolab"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.abbottbrasil.com.br/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/abbott.png"
              alt="Abbott"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://suanfarma.com/"
          >
            <img
              style="width:110%"
              src="@/assets/clientes/suanfarma.png"
              alt="Suanfarma"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.halexistar.com.br/"
          >
            <img
              style="width:110%;"
              src="@/assets/clientes/Halex Istar.png"
              alt="Halex Istar"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.blau.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Blau.png"
              alt="Blau Farmacêutica"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://cimedremedios.com.br/"
          >
            <img
              style="width:100%"
              src="@/assets/clientes/Cimed.png"
              alt="Cimed"
            />
          </b-link>
        </b-col>
      </b-row>
      <b-row style="margin-top:6em;" class="grid">
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="http://geolab.com.br/"
          >
            <img
              style="width:95%"
              src="@/assets/clientes/Geolab.png"
              alt="Geolab"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.pharlab.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Pharlab.png"
              alt="Pharlab"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.pratidonaduzzi.com.br/"
          >
            <img
              style="width:110%;"
              src="@/assets/clientes/pratidonaduzzi.png"
              alt="Prati Donaduzz"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="http://www.althaia.com.br/"
          >
            <img
              style="width:90%;"
              src="@/assets/clientes/Althaia.png"
              alt="Althaia"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.biochimico.ind.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/BioChimico.png"
              alt="BioChimico"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;max-width:25%"
            target="_blank"
            href="https://blanver.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Blanver.png"
              alt="Blanver"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;max-width:25%"
            target="_blank"
            href="https://laboratoriobergamo.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Bergamo.png"
              alt="Bergamo"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;max-width:25%"
            target="_blank"
            href="https://farmabase.com/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Farmabase.png"
              alt="Farmabase"
            />
          </b-link>
        </b-col>
      </b-row>
      <b-row style="margin-top:6em;" class="grid">
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.vitamedic.ind.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Vitamedic.png"
              alt="Vitamedic"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="http://hypofarma.com.br/site/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Hypofarma.png"
              alt="Hypofarma"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://brasil.catalent.com/?gclid=Cj0KCQiAjKqABhDLARIsABbJrGncPLk0nRBOQVKrhKXCPug6SZBAtRwKK-fDqlkQD2zJbzmmUwdDQZ8aAjGIEALw_wcB"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Catalent.png"
              alt="Catalent"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://catarinensepharma.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Catarinense Pharma.jpg"
              alt="Catarinense Pharma"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.kleyhertz.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Kley Hertz.png"
              alt="Kley Hertz"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://laboratorioglobo.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Laboratorio Globo.png"
              alt="Laboratório Globo"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://natulab.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Natulab.svg"
              alt="Natulab"
            />
          </b-link>
        </b-col>
        <b-col class="itens">
          <b-link
            style="background-color:transparent;width:25%"
            target="_blank"
            href="https://www.boehringer-ingelheim.com.br/"
          >
            <img
              style="width:100%;"
              src="@/assets/clientes/Boehringer.jpg"
              alt="Boehringer"
            />
          </b-link>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Clientes",
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .grid {
    display: block;
  }
  .itens {
    padding: 4em !important;
  }
  .baixa {
    margin-top: 3em !important;
  }
}
</style>
