<template>
  <div class="navBar  text-center">
    <b-nav align="center" class="opcoes mt-5">
      <b-navbar-brand class="navImg" href="/" style="width:15%">
        <img src="../assets/logo-spektra.png" style="width:20%" />
      </b-navbar-brand>
      <b-nav-item to="/">HOME</b-nav-item>
      <b-nav-item to="Atuacao#nossos">SERVIÇOS</b-nav-item>
      <b-nav-item to="Sobre#sobre">QUEM SOMOS</b-nav-item>
      <b-nav-item to="Publicacoes#artigos">PUBLICAÇÕES</b-nav-item>
      <b-nav-item to="clientes&parceiros#clientes">CLIENTES E PARCEIROS</b-nav-item>
      <!-- <b-nav-item to="/">BLOG</b-nav-item> -->
      <!-- <b-nav-item to="/">CURSOS ONLINE & MENTORIA</b-nav-item> -->
      <b-nav-item to="Contact">CONTATOS</b-nav-item>
      <div class="social">
        <!-- <b-link target="_blank" href=""><i class="fab fa-facebook-f fa-2x ml-2 mr-3" style="color:#330667!important;"></i></b-link> -->
        <b-link
          target="_blank"
          href="https://www.instagram.com/spektra_solucoes_cientificas/"
          ><i
            class="fab fa-instagram fa-2x ml-1"
            style="color:#330667!important;"
          ></i
        ></b-link>
        <b-link
          target="_blank"
          href="https://www.linkedin.com/company/spektra-consultoria/"
          ><i
            class="fab fa-linkedin fa-2x ml-4"
            style="color:#330667!important;"
          ></i
        ></b-link>
      </div>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped>
a {
  color: #330667 !important;
  font-size: large;
}
a:hover {
  color: #844cc9 !important;
  text-decoration-color: #98d02f !important;
  text-decoration-line: underline;
}
.nav-link:active {
  color: #c7abea !important ;
}
b-link {
  background-color: transparent !important;
  color: transparent !important;
  border: none;
}
b-link:hover {
  background-color: transparent !important;
  color: transparent !important;
  border: none;
}
b-link::before {
  background-color: transparent !important;
  color: transparent !important;
  border: none;
}
.navBar {
  position: fixed;
  width: 100%;
  top: 0%;
  background-color: white;
  margin-bottom: 10em;
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .logo {
    width: 30% !important;
  }
  .opcoes {
    display: block;
  }
  .social {
    margin-top: 2em;
  }
  .navImg {
    width: 60% !important;
  }
}
</style>
