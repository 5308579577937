<template>
  <div>
    <b-container class="p-4">
      <div v-animate-onscroll="{ down: 'animated pulse' }">
        <a href="/Sobre" style="text-decoration:none">
          <h2
            class="quem text-center"
            style="color:#330667;font-weight: bold;margin-top:10em;"
          >
            QUEM SOMOS
          </h2>
        </a>
        <br />
        <br />
        <p style="font-size: large;text-align:center">
          Somos uma empresa que oferece soluções científico-regulatórias,
          utilizando as ferramentas mais inovadoras provenientes de parcerias
          internacionais e estratégicas. Nossas alianças e networking na
          academia, indústria e na Anvisa, nos mantém atualizados e conectados
          com os melhores profissionais do mercado global. Com uma equipe
          experiente e altamente qualificada, oferecemos serviços de excelência
          para nossos clientes.
        </p>
      </div>
    </b-container>
    <div v-animate-onscroll="{ down: 'animated bounceInLeft' }">
      <b-container class="mt-5 p-5">
        <b-row>
          <b-col class="coluna p-4">
            <v-card class="cardBody" elevation="5" style="height: 50em;">
              <v-container style="text-align: center;margin-bottom:-7em">
                <v-icon class="icone">mdi-target-account</v-icon>
              </v-container>
              <v-card-title
                class="titleCard"
                style="color:#330667;font-weight: bolder;margin-top:3em;text-align:center;font-size: xx-large;padding:2em;"
                >MISSÃO</v-card-title
              >
              <v-card-text
                class="textCard"
                style="font-size: large;text-align:center;padding:1em;margin-top:-2em"
                >A partir da união entre a experiência acadêmica, a vivência na
                indústria farmacêutica e a compreensão prática das
                regulamentações, associadas ao conhecimento de mercado e ao
                empreendedorismo, buscamos contribuir, fortalecer e conectar
                este ecossistema promovendo sempre a sinergia entre esses
                pilares. Assim, fomentamos a evolução científica com o objetivo
                de garantir a saúde e a segurança da população.</v-card-text
              >
            </v-card>
          </b-col>
          <b-col class="coluna p-4">
            <v-card class="cardBody" elevation="5" style="height: 50em;">
              <v-container style="text-align: center;margin-bottom:-7em">
                <v-icon class="icone">mdi-eye-settings-outline</v-icon>
              </v-container>
              <v-card-title
                class="titleCard"
                style="color:#330667;font-weight: bolder;margin-top:3em;text-align:center;font-size: xx-large;padding:2em;"
                >VISÃO</v-card-title
              >
              <v-card-text
                class="textCard"
                style="font-size: large;text-align:center;padding:1em;margin-top:-2em"
                >Conectando as tendências internacionais com a realidade prática
                do mercado, visamos construir a sinergia entre os pilares que
                sustentam a indústria, nos transformando em referência pela
                excelência dos nossos serviços e contribuindo, não somente com a
                qualidade e segurança dos produtos, mas também com maior
                assertividade e agilidade dos processos. Além disso, sonhamos em
                desenvolver o conhecimento científico na indústria atuando em
                parceria com a academia, com o compromisso de compartilhar
                nossos ensinamentos para evolução de todos engajados no mesmo
                propósito.</v-card-text
              >
            </v-card>
          </b-col>
          <b-col class="coluna p-4">
            <v-card class="cardBody" elevation="5" style="height: 50em;">
              <v-container style="text-align: center;margin-bottom:-7em">
                <v-icon class="icone">mdi-puzzle-check-outline</v-icon>
              </v-container>
              <v-card-title
                class="titleCard"
                style="color:#330667;font-weight: bolder;margin-top:3em;text-align:center;font-size: xx-large;padding:2em;"
                >VALOR</v-card-title
              >
              <v-card-text
                class="textCard"
                style="font-size: large;text-align:center;padding:1em;margin-top:-2em"
              >
                Respeito, empatia, honestidade, comprometimento, inovação e
                espírito empreendedor. Entregamos soluções imparciais e de
                excelência para a evolução do mercado e da comunidade
                científica. Através de uma comunicação ética e respeitosa, nos
                dedicamos, sobretudo, a atuar focados na melhora da qualidade de
                vida de todos.
                <br />
                <br />“Não tente ser bem sucedido, tente antes ser um humano de
                valor” (Albert Einstein)
              </v-card-text>
            </v-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Missao",
};
</script>

<style scoped>
.icone {
  font-size: 10em !important;
  color: #340668 !important;
}

@media only screen and (max-width: 768px) {
  .titleCard {
    color: #330667 !important;
    font-weight: bolder !important;
    margin-top: 3em !important;
    text-align: center !important;
    font-size: small !important;
    padding: 1em !important;
    margin-left: 6em !important;
  }
  .icone {
    font-size: 8em !important;
    color: #340668 !important;
    margin-bottom: 0.5em;
  }
  .textCard {
    font-size: xx-small !important;
    text-align: center !important;
    padding: 1em !important;
    margin-top: 0.3em !important;
  }
  .cardBody {
    height: 30em !important;
  }
  .coluna {
    padding: 2em !important;
  }
  .quem {
    margin-top: 10em !important;
  }
}
</style>
